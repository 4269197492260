import React, { useState } from 'react';
import './Nav.css';
import ContactMe from './ContactMe';

 function Navbar() {
        const [isOpen, setIsOpen] = useState(false);
        const [isContactOpen, setIsContactOpen] = useState(false);

      
        function toggleNavbar() {
          setIsOpen(!isOpen);
        }
        const toggleContact = () => {
          setIsContactOpen(!isContactOpen);
        };
      
        return (
          <nav className="navbar">
            <div className="navbar-container">
              <div className="navbar-logo">
                <a href="/"><h1>R.S.CHAHAR</h1></a>
              </div>
              <div className={`navbar-links ${isOpen ? "active" : ""}`}>
                <a href="#Home">Home</a>
                <a href="#About">About</a>
                <a href="#Projects">Project</a>
                <button onClick={toggleContact} className='Nav_btn'>Contact</button>
                {isContactOpen && <ContactMe onClose={toggleContact} />}
              </div>
              <div className="navbar-toggle" onClick={toggleNavbar}>
                <span className="barr"></span>
                <span className="barr"></span>
                <span className="barr"></span>
              </div>
            </div>
          </nav>
        );
      }

export default Navbar;