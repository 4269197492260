import React, { useEffect } from 'react';
import Typed from 'typed.js';
import './Hero.css';
import Background from './Background';
import HireButton from './HireButton';
function Hero() {
  useEffect(() => {
    const options = {
      strings: ['Graphic Designer', 'Web Developer', 'UI/UX Designer'],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true
    };

    const typed = new Typed('.typed-text', options);

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className="hero-container" id='Home'>
      <Background/>
      <div className="hero-details">
        <h1>Hi, I'm Raj Singh Chahar.</h1>
        <p>A <span className='typed-text' /> Based in Chandigarh</p>
        <p id='P-small'>- Let's Craft Brilliance Together</p>
        <HireButton />
      </div>
    </div>
  );
}

export default Hero;
