import React, {useEffect} from 'react'
import './Background.css'
function getRandomPosition() {
    const offset = 10;
    const randomX = Math.random() * ((window.innerWidth - offset) * 1) + offset;
    const randomY = Math.random() * ((window.innerHeight - offset) * 1) + offset;
    return { x: randomX, y: randomY };
  }
  
  
  function getRandomColor() {
    const colors = ['#007bff', '#ff69b4', '#32cd32', '#ff6347', '#ffa500'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }
  
  function getRandomSpeed() {
    return Math.random() * 2 + 20; // Adjust speed range as needed
  }
  
  function Background() {
    
      useEffect(() => {
        const dots = document.querySelectorAll('.dot');
        dots.forEach(dot => {
          const { x, y } = getRandomPosition();
          const color = getRandomColor();
          const speed = getRandomSpeed();
          dot.style.left = `${x}px`;
          dot.style.right = `${-x}px`;
          dot.style.top = `${y}px`;
          dot.style.backgroundColor = color;
          dot.style.animationDuration = `${speed}s`;
        });
      }, []);
    
    return(
        <div className="dot-container">
        {Array.from({ length: 50 }).map((_, index) => (
          <div key={index} className={`dot dot-${index}`} />
        ))}
      </div>
    );
  }

  export default Background