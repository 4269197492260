import React from 'react';
import './Expertise.css';
import Background from './Background';
import Data from '../Data/Expertise.json'

function Expertise() {
  return (
    <div className='Expertise'>
    <Background />
    <h1>Exepertise Area</h1>
        <div className="expertise-container">
      <div className="expertise-column">
        <div key={Data.id} className="expertise-item">
            <h3>{Data.name}</h3>
            <p>{Data.content}</p>
        </div>
      </div>
      <div className="expertise-column">
        <div className="expertise-item">
        <h3>FULL-STACK WEB DEV</h3>
            <p>Having 1 year of Expertise in Website Development, Proficient in, BOOTSTRAP, TAILWIND, WORDPRESS & MERN STACK, Previously worked on more than 7 client based Project.</p>
        </div>
      </div>
      <div className="expertise-column">
        <div className="expertise-item">
        <h3>TECHNICAL PROFICIENCY</h3>
            <p>Proficient in C, C++ & Java Programming language With DSA.</p>
        </div>
      </div>
      <div className="expertise-column">
        <div className="expertise-item">
        <h3>ILLUSTRATION</h3>
            <p>Creating original artwork or incorporating existing illustrations into designs to enhance visual appeal and convey specific messages.</p>
        </div>
      </div>
      <div className="expertise-column">
        <div className="expertise-item">
        <h3>SOCIAL MEDIA MANAGEMENT</h3>
            <p>Having 2 years of Expertise in Social Media Management. I have Previously worked with more than 10 clients in this field</p>
        </div>
      </div>
      <div className="expertise-column">
        <div className="expertise-item">
        <h3>WORK EXPERIENCE</h3>
            <p>Working as a GRAPHIC DESIGNER | WEB DEVELOPER at Godigitify Nexus From March 2023 ~ Present</p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Expertise;
