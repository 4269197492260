import React from "react";
import ProgressBars from "./ProgressBars";

function Progress(){
    return(
        <div className="Progress_bars">
     <ProgressBars progress1={100} progress2={85} name1={'Adobe Photoshop'} name2={'Adobe Illustrator'} />
     <ProgressBars progress1={80} progress2={100} name1={'Premiere Pro'} name2={'Imovie'} />
     <ProgressBars progress1={95} progress2={80} name1={'Web Designing'} name2={'Web Development'} />
     </div>
    )
}

export default Progress;