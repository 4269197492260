import React from "react";
import './Footer.css';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';

function Footer(){
    return(
        <div className="Footer">
        <div className="Footer_left">
            <div className="Vertical_text">
            <a href="#hero-container">Home</a>
            <a href="#About">About</a>
            </div>
            <div class="Horizontal_text"><p>
            My initiatives can increase the value of your business.<br/>I specialize in delivering top-notch Graphic & Web<br/>Services that are tailored to meet your specific needs.<br/>If you have any lingering questions or concerns regarding a project, let's ~
            </p>
            <a href="https://www.linkedin.com/in/raj-singh-chahar-a1a336246/" target="_blank" rel="noreferrer" className="Footer_icon"><FaLinkedin/></a>
            <a href="https://twitter.com/Chahar_here" target="_blank" rel="noreferrer" className="Footer_icon"><FaTwitter/></a>
            </div>
        </div>
        <div className="Footer_right">
            <div class="Horizontal_text">
                <h1>Get In Touch 😊</h1>
                <p className="H_p">Send a mail :~</p>
                <p>Srchahar04@gmail.com</p>
                <p className="copyRight"><p className="copyRight">Copyright © 2024 Raj - All Rights Reserved.</p></p>
            </div>
        </div>
            
        </div>
    )
}

export default Footer;