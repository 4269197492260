import React, {useState} from 'react';
import './ContactMe.css';

function ContactMe({ onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        message: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        // After submission, reset the input fields
        setFormData({
          name: '',
          email: '',
          contact: '',
          message: ''
        });
      };

  return (
    <div className="contact-me-container">
    <button className="close-button" onClick={onClose}>X</button>
      <div className="contact-me-popup">
        <h1 className='Contact_h1'>Contact Raaz.</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contact No.:</label>
            <input type="text" id="contact" name="contact" value={formData.contact} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactMe;
