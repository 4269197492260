import React from "react";
import './About.css';
import DownloadCV from "./DownloadCV";
import raaj from '../Items/raaj.png';

function About(){
    return(
        <div className="about-container" id="About">
      <div className="text-container">
        <h1>About RAJ:</h1>
        <p>Graphic Designer with 2 years of experience. </p>
        <p>
        Web Developer with 1 year of experience.
        </p>
        <p>D4 Sviet Core Committee Member. </p>
          <DownloadCV />
      </div>
      <div className="image-container">
                <img src={raaj} alt="Raj Chahar" className="About_img"></img>
        </div>
     </div>
        
    )
}

export default About;