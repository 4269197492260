import React, { useState } from 'react';
import ContactMe from './ContactMe';
import './HireButton.css'


function App() {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const toggleContact = () => {
    setIsContactOpen(!isContactOpen);
  };

  return (
    <div>
      <button onClick={toggleContact} className='hire-button'>Get In Touch</button>
      {isContactOpen && <ContactMe onClose={toggleContact} />}
    </div>
  );
}

export default App;
