import React from 'react';
import './ProgressBars.css';

function ProgressBars({ progress1, progress2,name1, name2 }) {
  return (
    <div className="progress-bars-container">
      <div className="progress-bar">
        <span className="bar-label">{name1}</span>
        <div className="bar">
          <div className="progress" style={{ width: `${progress1}%` }}></div>
        </div>
      </div>
      <div className="progress-bar">
        <span className="bar-label">{name2}</span>
        <div className="bar">
          <div className="progress" style={{ width: `${progress2}%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBars;
