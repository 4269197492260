// export default Illustrations;
import React, { useState, useEffect } from 'react';
import A1 from '../Items/A1.png';
import A2 from '../Items/A2.png';
import A3 from '../Items/A3.png';
import A4 from '../Items/A4.png';
import A5 from '../Items/A5.png';
import A6 from '../Items/A6.png'
import './Illustrations.css';

function Illustrations() {
  const illustrations = [
    { id: 1, name: 'Illustration 1', imageUrl: A2, width: '200px' },
    { id: 2, name: 'Illustration 2', imageUrl: A3, width: '200px' },
    { id: 3, name: 'Illustration 3', imageUrl: A6, width: '200px' },
    { id: 4, name: 'Illustration 3', imageUrl: A5, width: '400px'},
    { id: 5, name: 'Illustration 3', imageUrl: A4, width: '100px' },
    { id: 1, name: 'Illustration 1', imageUrl: A1, width: '200px' },
    { id: 2, name: 'Illustration 2', imageUrl: A3, width: '200px' },
    { id: 3, name: 'Illustration 3', imageUrl: A4, width: '200px' },
    { id: 4, name: 'Illustration 3', imageUrl: A5, width: '400px'},
    { id: 5, name: 'Illustration 3', imageUrl: A1, width: '100px' },
    // { id: 3, name: 'Illustration 3', imageUrl: A1, width: '220px' },
  ];

  const [showItems, setShowItems] = useState(3); // Initial number of items to show
  const [hideItems, setHideItems] = useState(false);

  const handleLoadMore = (handleLoad) => {
    setShowItems((prev) => prev + 3);
  };

  // const handleHideItems = (handleHide) => {
  //   setHideItems((prev) => prev - 3)
  // }

  useEffect(() => {
    if (showItems >= illustrations.length) {
      setHideItems(true);
    } else {
      setHideItems(false);
    }
  }, [showItems, illustrations.length]);
  const handleHide = () => {
    setShowItems(3); // Reset to show only initial number of items
  };
  // Sample data for illustrations

  return (
   <>
     <div className="illustrations-container">
      {illustrations.slice(0, showItems).map((illustration) => (
        <div key={illustration.id} className="illustration-item card">
      <img src={illustration.imageUrl} alt={illustration.name} className=" illustration_img card-image" width={illustration.width} />
      </div>
      ))}
    </div>
    <div>
    {illustrations.length > showItems && (
        <div className='Load_btn'>
        <button className="load-more-btn" onClick={handleLoadMore}>
        Load More
        </button>
        </div>
      )}
    {hideItems && (
        <div className='Load_btn'>
        <button className="load-more-btn" onClick={handleHide}>
          Show less
        </button>
        </div>
      )}
    </div>
   </>
  );
}

export default Illustrations;
