import React from 'react';
import './Education.css';
import './Hero.css'
import Sviet from '../Items/Sviet.png'

function Education() {
  return (
    <div className="education-container">
    <h1 className="education-heading">Education</h1>
      <div className="education-content">
        <div className="education-card">
        <img src={Sviet} alt='College Logo'></img>
          <h3 className="college-name">Swami Vivevakanand Institute of Engineering & Technology</h3>
          <p className='College-location'>Banur, Chandigarh</p>
          <p className="degree-name">Btech, CSE</p>
          <p className="duration">2022-26</p>
        </div>
      </div>
    </div>
  );
}

export default Education;
