import React from 'react';
import RS from '../Items/RSChahar_Resume.pdf'
import './Download.css'

function DownloadCV() {
  const handleDownload = () => {
    // Replace 'your_resume_url.pdf' with the actual URL of your resume file
    const resumeUrl =  RS ;
    const link = document.createElement('a');
    link.href = resumeUrl;
    link.setAttribute('download', 'RajChaharResume.pdf'); // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='Download_CV'>
      <button onClick={handleDownload}>Download CV</button>
    </div>
  );
}

export default DownloadCV;
