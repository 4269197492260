import React, { useState } from 'react';
import './Projects.css';
import Illustrations from './Illustrations';
import Graphics from './Graphics';
import SocialMedia from './SocialMedia.js'
// import LoadMoreButton from './LoadMoreButton';

function Projects() {
  const [activeTab, setActiveTab] = useState('Illustration');
  const tabs = ['Illustration', 'Graphics', 'Social Media', 'Web Projects', 'Other Projects'];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="projects-container" id='Projects'>
    <h1 className='projects-container-h1'>Portfolio</h1>
      <nav className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </nav>
      <hr/>
      <div className="projects-section">
        {/* Illustration Section */}
        {activeTab === 'Illustration' && <Illustrations />}
        {/* Graphic Section */}
        {activeTab === 'Graphics' && <Graphics />}
        {activeTab === 'Social Media' && <SocialMedia />}
      </div>
    </div>
  );
}

export default Projects;
