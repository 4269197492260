// App.js
import React from 'react';
import Navbar from './components/Navbar';
import About from './components/About';
import Progress from './components/Progress';
import Expertise from './components/Expertise';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Projects from './components/Projects';
import Education from './components/Education.js';
import './components/App.css'

function App() {

  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Progress />
      <Expertise /> 
      <Projects />
      <Education />
      <Footer />
    </div>
  );
}

export default App;
